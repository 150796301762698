<template>
  <div class="player-controls">
    <div class="controls">
      <div class="time">
        <VueSlider
          class="seek-range"
          v-model="time"
          dotSize="22"
          tooltip="none"
          @change="seek"
        ></VueSlider>
        <br>
        <div class="time-labels">
          <span v-html="currentTime"></span>
          <span v-html="totalTime"></span>
        </div>
      </div>

      <button class="play-pause-btn" :class="value ? 'pause' : 'play'" @click="playPause"></button>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
  name: 'PlayerControls',
  components: { VueSlider },
  props: ['value', 'time', 'duration'],
  data () {
    return {
      volume: 100
    }
  },
  computed: {
    currentTime () {
      return this.formatTime(this.time / 100 * this.duration)
    },

    totalTime () {
      return this.formatTime(this.duration)
    }
  },
  methods: {
    playPause () {
      if (this.value) {
        this.$emit('pause')
        this.$emit('input', false)
      } else {
        this.$emit('play')
        this.$emit('input', true)
      }
    },
    seek (pos) {
      this.$emit('seek', pos)
    },
    formatTime (val) {
      const sec = parseInt(val, 10)
      const hours = Math.floor(sec / 3600)
      const minutes = Math.floor((sec - (hours * 3600)) / 60)
      let seconds = sec - (hours * 3600) - (minutes * 60)

      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      if (val >= 3600) {
        return `${hours}:${minutes}:${seconds}`
      } else {
        return `${minutes}:${seconds}`
      }
    }
  },
  watch: {
    volume () {
      this.$emit('volume', this.volume / 100)
    }
  }
}
</script>
