<template>
  <div class="page page-fullscreen home">
    <div class="header">
      <div class="container">
        <h1 class="page-title">700 Jahre Geschichte und Traditionen</h1>
      </div>
    </div>

    <div class="content">
      <div class="container">
        <div class="intro">
          <div class="raster-img"><div class="raster-white"><div class="logo-bsb"><img src="@/assets/KeyNoisy2k.jpg" class="raster-fill"></div></div></div>
          <p style="margin-top: 10px;">Jubiläum des St. Johanni Bürgerschützenvereins Borken e.V.</p>
          <p>Ausstellung im FARB</p>
          <p>13.8.2023 – 11.2.2024</p>
          <ul class="language-nav" style="width: 100%; text-align: right;">
            <li><router-link :to="{ name: 'tour', params: { id: 1 }}">Weiter</router-link></li>
          </ul>
          <ul class="language-nav">
            <li style="margin-top: 4em;"><router-link :to="{ name: 'imprint' }">Impressum</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'home',
  computed: mapState(['languages'])
}
</script>
