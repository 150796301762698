<template>
  <div class="cube">
    <h2>{{ $t('go-to-cube-side') }} {{ content.kubus_seite }}</h2>
  </div>
</template>

<script>
export default {
  name: 'Cube',
  props: ['content']
}
</script>
