<template>
  <div id="app">
    <!--
    <Header v-if="['home', 'contact', 'imprint'].indexOf($route.name) === -1"></Header>
    -->
    <router-view></router-view>

    <Alert></Alert>

    <transition name="scale">
      <Fullscreen v-if="fullScreen.length > 0" :img="fullScreen">{{ fullScreen }}</Fullscreen>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Alert from '@/components/Alert'
import Fullscreen from '@/components/Fullscreen'

export default {
  name: 'App',
  components: { Alert, Fullscreen },
  computed: mapState(['fullScreen']),
  async mounted () {
    this.$store.dispatch('fetchLanguages').then(async () => {
      await this.$store.dispatch('fetchTours')
      await this.$store.dispatch('fetchExhibits')
    })
  }
}
</script>

<style lang="scss">
@import "@/styles/app.scss";
</style>
