<template>
  <transition name="fade">
    <div class="animation" v-if="showOverlay">
      <div class="overlay">
        <video
          ref="video"
          preload="auto"
          muted
          playsinline
          :src="animation"
          @ended="animationEnd"
          v-if="showAnimation"
        ></video>

        <img :src="`./animation/${cubeSide}.png`" v-if="!showAnimation && cubeSide.length > 0" @load="imageLoaded = true">

        <transition name="fade">
          <div class="pos" v-if="showPos && exhibitNumber" :style="posPos" v-html="exhibitNumber"></div>
        </transition>
      </div>
      <h1 v-html="switchSideLabel" @click="hideAll"></h1>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PositionOverlay',
  data () {
    return {
      animation: '',
      showPos: false,
      showOverlay: false,
      showAnimation: false,
      showSide: false,
      switchSideLabel: '',
      imageLoaded: false,
      hideTimeout: null
    }
  },
  props: {
    cubeSide: {
      type: String,
      required: true
    },
    cubePos: {
      type: Object,
      default: () => ({ x: false, y: false })
    },
    exhibitNumber: {
      type: [Number, Boolean],
      default: false
    }
  },
  mounted () {
    this.hideAll()
    this.$watch(vm => [vm.cubeSide, vm.cubePos], this.updateSidePos)
  },

  methods: {
    updateSidePos ([newSide, newPos], [oldSide, oldPos]) {
      this.hideAll()

      const sideChanged = newSide.length > 0 && oldSide.length > 0 && newSide !== oldSide
      const posChanged = newPos.x !== false && newPos.y !== false && (newPos.x !== oldPos.x && newPos.y !== oldPos.y)

      if (!sideChanged && !posChanged) {
        return
      }
/*
      this.showOverlay = true
      this.hideTimeout = setTimeout(() => {
        this.imageLoaded = false
        this.hideAll()
      }, 8000)

      if (sideChanged) {
        this.animation = `animation/${oldSide}_${newSide}.mp4`
        this.switchSideLabel = posChanged
          ? this.$t('switch-side-and-pos', { side: newSide, exhibit: this.exhibitNumber })
          : this.$t('switch-side', { side: newSide })
        this.playAnimation()
      } else if (!sideChanged && posChanged) {
        this.switchSideLabel = this.$t('switch-side-and-pos', { side: newSide, exhibit: this.exhibitNumber })
        this.switchSideLabel = this.$t('go-to-exhibit', { exhibit: this.exhibitNumber })
        this.showPosition()
      }

 */
    },

    playAnimation () {
      this.showAnimation = true
      setTimeout(() => {
        if (this.$refs.video) {
          this.$refs.video.play()
        }
      }, 500)
    },

    showPosition () {
      this.showPos = true
      if (this.showAnimation) {
        setTimeout(() => {
          this.hideAll()
          this.imageLoaded = false
        }, 1500)
      }
    },

    animationEnd () {
      // if position available, fade in after video end and then hide 1.5s later
      if (this.cubePos.x !== false && this.cubePos.y !== false) {
        this.showPosition()
      } else {
        // if no position, hide overlay directly
        this.hideAll()
      }
    },

    hideAll () {
      this.showOverlay = false
      this.showAnimation = false
      this.showPos = false
      window.clearTimeout(this.hideTimeout)
    }
  },
  computed: {
    posPos () {
      return {
        top: `${this.cubePos.y}%`,
        left: `${this.cubePos.x}%`
      }
    }
  },
  watch: {
    imageLoaded (loaded) {
      if (loaded && this.showPos) {
        setTimeout(() => {
          this.hideAll()
          this.imageLoaded = false
        }, 1500)
      }
    }
  }
}
</script>
