import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home.vue'
import Contact from '@/views/Contact.vue'
import Imprint from '@/views/Imprint.vue'
import Language from '@/views/Language.vue'
import Tour from '@/views/Tour.vue'
import NotFound from '@/views/404.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/:lang',
      component: Language,
      children: [
        {
          path: '',
          name: 'home',
          component: Home
        },
        {
          path: 'kontakt',
          name: 'contact',
          component: Contact
        },
        {
          path: 'impressum',
          name: 'imprint',
          component: Imprint
        },
        {
          path: 'tours/:id',
          name: 'tour',
          component: Tour,
          children: [
            {
              path: 'item/:item',
              name: 'item',
              component: Tour
            }
          ]
        },
      ]
    },
    {
      path: '*',
      name: '404',
      component: NotFound
    }
  ]
})

export default router
