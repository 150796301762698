import axios from 'axios'

const url = 'post.php'
const iOS = typeof window.webkit !== 'undefined' &&
  typeof window.webkit.messageHandlers !== 'undefined' &&
  typeof window.webkit.messageHandlers.interOp !== 'undefined'

const send = async msg => {
  if (iOS) {
    window.webkit.messageHandlers.interOp.postMessage(msg)
  } else {
    await axios.post(url, msg)
  }
}

const incrementViews = async id => {
  const message = { typ: 'exponat', id }
  await send(message)
}

const sendContactForm = async ({ name, kontakt, nachricht }) => {
  const message = { typ: 'kontakt', name, kontakt, nachricht }
  await send(message)
}

export default {
  incrementViews,
  sendContactForm
}
