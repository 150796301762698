<template>
  <div class="snap-slider" ref="slider" :class="{ visible, fade }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SnapSlider',

  data () {
    return {
      slides: 0,
      currentSlide: 0,
      visible: true,
      fade: true
    }
  },

  mounted () {
    let pos = 0
    this.updateSliderSize()

    this.slides = this.$slots.default.filter(item => typeof item.tag !== 'undefined').length

    this.$refs.slider.addEventListener('scroll', () => {
      const newPos = this.$refs.slider.scrollLeft
      if (Math.abs(pos - newPos) > window.innerWidth / 2) {
        pos = newPos
        this.currentSlide = Math.round(pos / window.innerWidth)
      }
    })

    this.$refs.slider.childNodes.forEach((slide, i) => {
      slide.id = `slide-${i}`
    })
  },

  methods: {
    select (index) {
      this.visible = false
      this.fade = false
      this.$refs.slider.scrollLeft = window.innerWidth * index
      this.fade = true
      setTimeout(() => {
        this.visible = true
      }, 500)
    },

    next () {
      if (this.currentSlide < this.slides) {
        this.select(this.currentSlide + 1)
      }
    },
    prev () {
      if (this.currentSlide > 0) {
        this.select(this.currentSlide - 1)
      }
    },

    updateSliderSize () {
      // update slider height
      let height = this.$refs.slider.children[this.currentSlide].firstChild.clientHeight
      if (height > 0) {
        height = `${height}px`
      } else {
        height = 'auto'
      }
      this.$refs.slider.style.height = height

      // set each slide's scroll position to the top
      setTimeout(() => {
        for (const slide of this.$refs.slider.children) {
          slide.firstChild.scrollTop = 0
        }
      }, 100)
    }
  },

  watch: {
    currentSlide () {
      this.$emit('change', this.currentSlide)
      this.updateSliderSize()
    }
  },

  computed: {
    sliderHeight () {
      return this.height
    }
  }
}
</script>
