<template>
  <p class="text">
    <router-link
      :to="{ name: 'exhibit', params: { id: content.exponat } }"
      v-html="content.text"
    ></router-link>
  </p>
</template>

<script>
export default {
  name: 'ExhibitLink',
  props: ['content']
}
</script>
