<template>
  <div class="page contact page-fullscreen">
    <div class="header">
      <div class="container">
        <h1 class="page-title">Die Seite konnte nicht gefunden werden.</h1>
        <div class="home-nav">
          <img src="@/assets/icons/schliessen.svg" @click="$router.go(-1)" class="close-btn">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact'
}
</script>
