<template>
  <div>
    <Block
      v-for="(block, i) in content.bloecke"
      :key="`${content.exponat_id}-block-${i}`"
      :block="block"
      :index="index"
    ></Block>
  </div>
</template>

<script>
import Block from '@/components/Block'

export default {
  name: 'Content',
  props: {
    content: {
      type: Object
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: { Block }
}
</script>
