<template>
  <div class="lang-switcher" v-if="languages.length > 0" :class="isToggled ? 'toggled' : ''">
    <button class="selected-lang" @click="toggle">{{ selectedLangName }}</button>

    <ul v-if="isToggled">
      <li v-for="lang in nonActiveLangs" :key="lang.sprache_id" @click="toggle">
        <router-link :to="langLink(lang)">{{ lang.sprache_lang }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LangSwitcher',

  data () {
    return {
      isToggled: false
    }
  },

  methods: {
    toggle () {
      this.isToggled = !this.isToggled
    },

    langLink (lang) {
      return { name: this.$route.name, params: { lang: lang.sprache_kurz.toLowerCase() } }
    }
  },

  computed: {
    ...mapState(['languages']),
    ...mapGetters(['activeLang']),

    selectedLangName () {
      return this.isToggled ? this.activeLang.sprache_lang : this.activeLang.sprache_kurz
    },

    nonActiveLangs () {
      return this.languages.filter(lang => !lang.active)
    }
  }
}
</script>
