<template>
  <div>
    <div class="section-title" :id="`${slug(block.anker)}-${index}`" v-if="block.anker"></div>
    <component :is="block.typ" :content="block"></component>
  </div>
</template>

<script>
import Title from '@/components/Title'
import TextBlock from '@/components/TextBlock'
import Image from '@/components/Image'
import Video from '@/components/Video'
import Audio from '@/components/Audio'
import PanoCarousel from '@/components/PanoCarousel'
import ExhibitLink from '@/components/ExhibitLink'
import { slug } from '@/helpers'

export default {
  name: 'Block',
  props: ['block', 'index'],
  methods: { slug },

  components: {
    'block-themenueberschrift': Title,
    'block-text': TextBlock,
    'block-bild': Image,
    'block-video': Video,
    'block-audio': Audio,
    'block-pano': PanoCarousel,
    'block-link': ExhibitLink
  }
}
</script>
