<template>
  <div class="video">
    <h4 v-if="content.titel && content.titel.length > 0">«{{ content.titel }}»</h4>
    <p v-if="content.copyright && content.copyright.length > 0">© {{ content.copyright }}</p>

    <video
      ref="video"
      @timeupdate="timeUpdate"
      @ended="playing = false"
      :poster="content.poster"
      playsinline
    >
      <source :src="content.mp4" type="video/mp4">
    </video>

    <PlayerControls
      @play="play"
      @pause="pause"
      @seek="seek"
      :time="time"
      :duration="duration"
      v-model="playing"
      @volume="volume"
    >
    </PlayerControls>
  </div>
</template>

<script>
import PlayerControls from '@/components/PlayerControls'

export default {
  name: 'Video',
  props: ['content'],
  components: { PlayerControls },
  data () {
    return {
      time: 0,
      duration: 0,
      playing: false
    }
  },
  methods: {
    timeUpdate () {
      this.time = this.$refs.video.currentTime / this.duration * 100
    },
    seek (pos) {
      this.$refs.video.currentTime = pos / 100 * this.duration
    },
    play () {
      this.$refs.video.play()
    },
    pause () {
      this.$refs.video.pause()
    },
    volume (val) {
      this.$refs.video.volume = val
    }
  },
  mounted () {
    let checkVideoState

    if (typeof this.$refs.video !== 'undefined') {
      checkVideoState = setInterval(() => {
        if (typeof this.$refs.video !== 'undefined' && this.$refs.video.readyState === 4) {
          this.duration = this.$refs.video.duration
          // set player controls when the video is paused programatically
          this.$refs.video.addEventListener('pause', () => {
            this.playing = false
          })
          clearInterval(checkVideoState)
        }
      }, 10)
    } else {
      clearInterval(checkVideoState)
    }
  }
}
</script>
