<template>
  <transition name="scale">
    <div class="alert-wrapper" v-if="alert.show">
      <div class="alert">
        <div class="alert-content">
          {{ alert.title }}
        </div>
        <button v-if="alert.btn" @click="dismiss" class="icon-btn icon-arrow">{{ alert.btn }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Alert',
  computed: mapState(['alert']),
  methods: {
    dismiss () {
      this.$store.commit('toggleAlert', false)
    }
  }
}
</script>
