<template>
  <div class="page contact page-fullscreen">
    <div class="header">
      <div class="container">
        <div class="home-nav">
          <img src="@/assets/icons/schliessen.svg" @click="back" class="close-btn">
        </div>
        <h1 class="page-title">{{ $t('write-us') }}</h1>
      </div>
    </div>

    <div class="contact">
      <div class="container">
        <form class="form" @submit.prevent="submit">
          <p>
            <label for="name">{{ $t('your-name') }} <small>(optional)</small></label>
            <input type="text" name="name" id="name" v-model="name">
          </p>
          <p>
            <label for="email-phone">{{ $t('your-email-phone') }} <small>(optional)</small></label>
            <input type="text" name="email-phone" id="email-phone" v-model="kontakt">
          </p>
          <p>
            <label for="nachricht">{{ $t('your-message') }}</label>
            <textarea type="text" name="nachricht" id="nachricht" v-model="nachricht"></textarea>
          </p>
          <p>
            <button class="icon-btn icon-arrow submit-btn" @click.prevent="submit" v-if="!sent">{{ $t('send') }}</button>
            <span class="icon-btn" v-else>{{ $t('sent') }}</span>
            <button class="icon-btn icon-arrow right-align" @click="back" v-if="sent">{{ $t('back-to-menu') }}</button>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact',

  data () {
    return {
      sent: false,
      name: '',
      kontakt: '',
      nachricht: ''
    }
  },

  methods: {
    back () {
      this.$router.go(-1)
    },

    async submit () {
      // nachricht is a required field
      if (this.nachricht.length === 0) return

      await this.$store.dispatch('sendContactForm', {
        name: this.name,
        kontakt: this.kontakt,
        nachricht: this.nachricht
      })

      this.sent = true
    }
  }
}
</script>
