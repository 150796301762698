import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import i18n from '@/i18n'
import api from '@/api'
import { slug } from '@/helpers'

Vue.use(Vuex)

const state = {
  languages: [],
  loading: false,
  alert: {
    title: '',
    btn: '',
    show: false
  },
  fullScreen: '',
  tours: [],
  tour: {
    titel: '',
    sequenz: []
  },
  exhibits: [],
  exhibitsFrom: 1,
  exhibitsTo: 135,
  exhibit: {
    exponatsbezeichnung: ''
  },
  quiz: {
    questions: [],
    score: 0
  },
  imprint: {}
}

const getters = {
  activeLang: state => {
    return state.languages.length > 0
      ? state.languages.find(lang => lang.active === true)
      : false
  }
}

const mutations = {
  setLanguages (state, langs) {
    state.languages = langs.map(lang => {
      lang.active = lang.sprache_kurz.toUpperCase() === i18n.locale.toUpperCase()
      return lang
    })
  },

  setAlert (state, alert) {
    state.alert = alert
  },

  toggleAlert (state, show) {
    state.alert.show = show
  },

  setActiveLang (state, langId) {
    state.languages = state.languages.map(lang => {
      lang.active = lang.sprache_id === langId
      return lang
    })
  },

  enterFullscreen (state, url) {
    state.fullScreen = url
    document.body.style = 'overflow:hidden'
  },

  exitFullscreen (state) {
    state.fullScreen = ''
    document.body.style = ''
  },

  setTours (state, tours) {
    state.tours = tours
  },

  setImprint (state, imprint) {
    state.imprint = imprint
  },

  setTour (state, tour) {
    state.tour = tour

    if (tour.typ === 'quiz') {
      state.quiz.score = 0
      state.quiz.questions = tour.sequenz.filter(item => item.typ === 'tourexponat')
    }

    // build exhibit nav
    if (tour.typ === 'tour') {
      state.tour.sequenz.forEach((exhibit, i) => {
        if (exhibit.typ === 'tourexponat') {
          const nav = []
          exhibit.bloecke.forEach(block => {
            if (block.anker) {
              nav.push({
                slug: `#${slug(block.anker)}-${i}`,
                title: block.anker
              })
            }
          })

          exhibit.nav = nav
        }
      })
    }
  },

  setLoading (state, loading) {
    state.loading = loading
  },

  setExhibitsFrom (state, from) {
    state.exhibitsFrom = from
  },

  setExhibitsTo (state, to) {
    state.exhibitsTo = to
  },

  setExhibits (state, exhibits) {
    state.exhibits = exhibits
  },

  setExhibit (state, exhibit) {
    const nav = []
    exhibit.bloecke.forEach(block => {
      if (block.anker) {
        nav.push({
          slug: `#${slug(block.anker)}-0`,
          title: block.anker
        })
      }
    })
    exhibit.nav = nav
    state.exhibit = exhibit
  },

  increaseScore (state, points = 1) {
    state.quiz.score += points
  }
}

const actions = {
  async fetchLanguages ({ commit }) {
    try {
      const languages = await axios.get('/json/sprachen.json')
      commit('setLanguages', languages.data)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e.message)
    }
  },

  async switchLang ({ commit, dispatch }, langId) {
    commit('setActiveLang', langId)
    await dispatch('fetchTours')
    await dispatch('fetchExhibits')
  },

  async sendContactForm (_, message) {
    try {
      await api.sendContactForm(message)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e.message)
    }
  },

  async fetchImprint ({ commit, getters }) {
    if (getters.activeLang && getters.activeLang.sprache_id) {
      try {
        const langId = getters.activeLang.sprache_id
        const response = await axios.get(`/json/impressum-${langId}.json`)
        commit('setImprint', response.data)
      } catch (e) {
        // eslint-disable-next-line
        console.error(e.message)
      }
    }
  },

  async fetchTours ({ commit, getters }) {
    try {
      const langId = getters.activeLang.sprache_id
      if (langId) {
        const response = await axios.get(`/json/tourlist-${langId}.json`)
        if (typeof response.data.touren !== 'undefined') {
          commit('setTours', response.data.touren)
        }
      }
    } catch (err) {
      // eslint-disable-next-line
      console.error(err.message)
    }
  },

  async fetchTour ({ commit, getters, state }, id) {
    if (getters.activeLang) {
      try {
        const tour = state.tours.find(tour => tour.tour_id === parseInt(id))
        if (tour) {
          const tourLink = `/json/tour-${getters.activeLang.sprache_id}-${tour.tour_id}.json`
          const response = await axios.get(tourLink)
          commit('setTour', response.data)
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }
    }
  },

  async fetchExhibits ({ commit, getters }) {
    if (getters.activeLang) {
      try {
        const exhibitsLink = `/json/exponatlist-${getters.activeLang.sprache_id}.json`
        const response = await axios.get(exhibitsLink)
        if (response.data && response.data.exponate) {
          const numbers = response.data.exponate.map(exp => exp.exponat_id)
          commit('setExhibitsFrom', numbers.length > 0 ? Math.min.apply(null, numbers) : 1)
          commit('setExhibitsTo', numbers.length > 0 ? Math.max.apply(null, numbers) : 135)
          commit('setExhibits', response.data.exponate)
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async incrementViews (ctx, id) {
    /*
    try {
      await api.incrementViews(id)
    } catch (err) {
      // eslint-disable-next-line
      console.error(err.message)
    }
   */
  },

  async fetchExhibit ({ commit, getters, dispatch }, id) {
    try {
      const exponatLink = `/json/exponat-${getters.activeLang.sprache_id}/${id}.json`
      const response = await axios.get(exponatLink)
      commit('setExhibit', response.data)
      dispatch('incrementViews', id)
    } catch (err) {
      // eslint-disable-next-line
      console.error(err.message)
    }
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
