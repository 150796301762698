<template>
  <div class="page page-fullscreen white">
    <div class="header">
      <div class="container">
        <div class="home-nav">
          <img src="@/assets/icons/schliessen.svg" @click="back" class="close-btn">
        </div>
        <h1 class="page-title">{{ $t('imprint') }}</h1>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <Block
          v-for="(block, i) in imprint.bloecke"
          :block="block"
          :key="`${imprint.sprache_id}-block-${i}`"
        ></Block>
        <br><p>&nbsp;</p><br>
        Das Projekt wurde großzügig gefördert von
        <br>
        <div class="impouter"><div class="implogo" id="logo_min"></div></div>
        <div class="impouter"><div class="implogo" id="logo_lwl"></div></div>
        <br><p>&nbsp;</p><br>
        und realisiert mit freundlicher Unterstützung von
        <br>
        <div class="impouter"><div class="implogo" id="logo_swb"></div></div>
        <div class="impouter"><div class="implogo" id="logo_spk"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Block from '@/components/Block'

export default {
  name: 'imprint',
  components: { Block },

  async mounted () {
    await this.fetchContent()
  },

  methods: {
    async fetchContent () {
      await this.$store.dispatch('fetchImprint')
    },
    back () {
      this.$router.go(-1)
    }
  },

  computed: mapState(['imprint'])
}
</script>
