<template>
  <div class="quiz-slide">
    <div class="container content">
      <div class="quiz-header">
        <h1 class="quiz-title" v-html="quizTitle"></h1>
        <p class="score">
          <span class="points">{{ quiz.score }}</span>
          {{ $t('from-x-points', { x: fromTotal }) }}
        </p>
      </div>

      <div v-if="!showAnswer">
        <div class="questions">
          <p class="hint" v-if="content.hinweis1">{{ $t('first-hint') }}</p>
          <p class="question" v-if="content.hinweis1">{{ content.hinweis1 }}</p>

          <p class="hint" v-if="content.hinweis2 && showSecondHint">{{ $t('second-hint') }}</p>
          <p class="question" v-if="content.hinweis2 && showSecondHint">{{ content.hinweis2 }}</p>

          <div class="quiz-buttons">
            <button class="confirm-entry icon-btn icon-arrow" @click="showSecondHint = true" v-if="!showSecondHint">
              {{ $t('new-hint') }}
            </button>

            <button class="confirm-entry icon-btn icon-arrow show-answer-btn" @click="showAnswer = true" v-if="!showAnswer">
              {{ $t('show-answer') }}
            </button>
          </div>
        </div>

        <p>{{ $t('enter-solution') }}</p>

        <KeyPad @entry="handleEntry" :alert="wrongAnswer ? $t('wrong-answer') : ''"></KeyPad>

        <div class="try-again-btns" v-if="!showAnswer && wrongAnswer">
          <button class="confirm-entry icon-btn icon-arrow" @click="wrongAnswer = ''">
            {{ $t('try-again') }}
          </button>
          <button class="confirm-entry icon-btn icon-arrow show-answer-btn" @click="showAnswer = true">
            {{ $t('show-answer') }}
          </button>
        </div>
      </div>
    </div>
    <Exponat :content="content" @next="$emit('next')" v-if="showAnswer"></Exponat>
  </div>
</template>

<script>
import Exponat from '@/components/Exponat.vue'
import KeyPad from '@/components/KeyPad.vue'
import { mapState } from 'vuex'

export default {
  name: 'QuizSlide',
  props: ['content', 'index'],
  components: { Exponat, KeyPad },
  computed: {
    ...mapState(['quiz']),
    quizTitle () {
      if (this.showAnswer && this.wrongAnswer === false) {
        return this.$t('correct') + '!'
      } else if (this.showAnswer) {
        return this.$t('answer')
      } else {
        return this.$t('quiz')
      }
    },
    fromTotal () {
      return (this.index + 1) * 2
    }
  },
  data () {
    return {
      showAnswer: false,
      showSecondHint: false,
      wrongAnswer: null
    }
  },

  methods: {
    handleEntry (entry) {
      this.wrongAnswer = parseInt(entry) !== this.content.exponat_id
      if (!this.wrongAnswer) {
        this.$store.commit('increaseScore', this.showSecondHint ? 1 : 2)
        this.showAnswer = true
      }
    }
  }
}
</script>
