import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto'
import Nl2br from 'vue-nl2br'

Vue.use(VueScrollTo, { offset: -64 })
Vue.config.productionTip = false
// register nl2br component globally
Vue.component('nl2br', Nl2br)

/**
 * Load language for locale from the public folder
 */
function loadDynamicLang (locale) {
  return new Promise((resolve, reject) => {
    axios.get(`/lang/${locale}.json`).then(res => {
      i18n.setLocaleMessage(locale, res.data)
      resolve(res.data)
    }).catch(err => reject(err))
  })
}
loadDynamicLang('de')

/**
 * Handle language switching
 */
router.beforeEach(async (to, from, next) => {
  const lang = to.params.lang
  // when no lang found, redirect to DE
  if (['de', 'en', 'ls'].indexOf(lang) === -1) {
    await router.push('de')
    return
  }

  i18n.locale = to.params.lang
/*
  if (to.name === 'tour') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
*/
  if (to.params.lang !== from.params.lang) {
    loadDynamicLang(lang).finally(() => {
      const selectedLang = store.state.languages.find(l => {
        return l.sprache_kurz === lang.toUpperCase()
      })
      console.log("router.beforeEach " + from.params.lang + " -> " + to.params.lang + " : " + selectedLang + " - " + store.state.languages.length);
      if (selectedLang) {
        store.dispatch('switchLang', selectedLang.sprache_id)
      }
      return next()
    })
  } else {
    console.log("router.beforeEach same same");
    return next()
  }
})

axios.interceptors.request.use(
  config => {
    store.commit('setLoading', true)
    return config
  },
  error => {
    store.commit('setLoading', false)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    store.commit('setLoading', false)
    return response
  },
  err => {
    store.commit('setLoading', false)
    return Promise.reject(err)
  }
)

router.push('/')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
