<template>
  <div :class="small ? 'header header-small' : 'header'">
    <div id="navigator" :class="navigatorClass" @click="navigateBack">
      <i></i>
      <i></i>
    </div>

    <div class="container">
      <div class="home-nav">
        <div style="width: 100%;">
          <LangSwitcher></LangSwitcher>

          <router-link :to="{ name: 'contact' }" class="contact-link">
            <img src="@/assets/icons/Nachricht.svg" class="contact-btn">
          </router-link>
        </div>
      </div>

      <h1 class="page-title" v-html="$t('welcome')"></h1>
    </div>

    <div class="tabs">
      <div class="container">
        <div class="inner-content">
          <li class="tab">
            <router-link :to="{name: 'key-pad'}">{{ $t('enter-number') }}</router-link>
          </li>
          <li class="tab">
            <router-link :to="{name: 'tour-list'}">{{ $t('select-tour') }}</router-link>
          </li>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import LangSwitcher from '@/components/LangSwitcher.vue'

export default {
  name: 'Header',
  components: { LangSwitcher },
  computed: {
    small () {
      return ['tour-list', 'key-pad'].indexOf(this.$route.name) === -1
    },
    backLink () {
      const pages = {
        exhibit: 'key-pad',
        tour: 'tour-list'
      }
      const name = pages[this.$route.name] || ''
      return (name).length > 0 ? { name } : false
    },
    navigatorClass () {
      const pages = {
        'key-pad': 'left',
        'tour-list': 'right'
      }
      return pages[this.$route.name] || ''
    }
  },
  methods: {
    navigateBack () {
      const pages = {
        exhibit: 'key-pad',
        tour: 'tour-list'
      }
      const name = pages[this.$route.name] || ''
      if (name.length > 0) {
        this.$router.push({ name })
      }
    }
  }
}
</script>
