<template>
  <div class="keypad sub-page">
    <div class="container">
      <div class="display">
        <div class="keypad-entry">
          <span v-html="number"></span>
        </div>
        <button class="confirm-entry icon-btn icon-arrow" @click="confirm">{{ $t('accept') }}</button>
      </div>

      <div class="keys" :class="alert && alert.length > 0 ? 'hide-keys' : ''">
        <button class="key" @click="keyPress(1)">1</button>
        <button class="key" @click="keyPress(2)">2</button>
        <button class="key" @click="keyPress(3)">3</button>
        <button class="key key-del key-mobile" @click="keyPress(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="54.7" height="25.5" viewBox="0 0 54.7 25.5">
            <g id="Gruppe_18" data-name="Gruppe 18" transform="translate(-628 -864.3)">
              <g id="Gruppe_17" data-name="Gruppe 17">
                <line id="Linie_1" data-name="Linie 1" x1="43.7" transform="translate(639 877)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="6"/>
                <g id="Gruppe_16" data-name="Gruppe 16">
                  <path id="Pfad_15" data-name="Pfad 15" d="M640.7,889.8V864.3L628,877.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button class="key" @click="keyPress(4)">4</button>
        <button class="key" @click="keyPress(5)">5</button>
        <button class="key" @click="keyPress(6)">6</button>
        <button class="key key-empty key-mobile"></button>
        <button class="key" @click="keyPress(7)">7</button>
        <button class="key" @click="keyPress(8)">8</button>
        <button class="key" @click="keyPress(9)">9</button>
        <button class="key key-mobile" @click="keyPress(0)">0</button>
        <button class="key key-empty key-desktop"></button>
        <button class="key key-del key-desktop" @click="keyPress(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="54.7" height="25.5" viewBox="0 0 54.7 25.5">
            <g id="Gruppe_18" data-name="Gruppe 18" transform="translate(-628 -864.3)">
              <g id="Gruppe_17" data-name="Gruppe 17">
                <line id="Linie_1" data-name="Linie 1" x1="43.7" transform="translate(639 877)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="6"/>
                <g id="Gruppe_16" data-name="Gruppe 16">
                  <path id="Pfad_15" data-name="Pfad 15" d="M640.7,889.8V864.3L628,877.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button class="key key-desktop" @click="keyPress(0)">0</button>

        <h2 class="keypad-alert" v-if="alert && alert.length > 0">{{ alert }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Keypad',
  props: ['alert'],

  data () {
    return {
      number: ''
    }
  },

  methods: {
    keyPress (num) {
      if (num > -1 && this.number.length < 3) {
        this.number += num
      } else if (num === -1 && this.number.length > 0) {
        this.number = this.number.substring(0, this.number.length - 1)
      }
    },

    confirm () {
      if (this.number.length === 0) return
      this.$emit('entry', this.number)
      this.number = ''
    }
  }
}
</script>
