<template>
  <div class="exponat exhibit">
    <div class="page-header">
      <div class="container">
        <div class="exhibit-number-nav">
        </div>

        <h1 class="page-title" v-html="content.exponatsbezeichnung"></h1>
      </div>
    </div>

    <div class="container content">
      <div class="exponat-content">
        <!--
        <div class="raster-img" style="margin-top: 2em; margin-bottom: 1.5em;"><div class="raster-white"><img src="@/assets/KeyNoisy2k.jpg" class="raster-fill"></div></div>
        -->
        <Content :content="content" :index="index"></Content>
        <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: right">
          <button class="icon-btn icon-arrow"     @click="nextObject" v-html="nextLabel" style="padding: 0;" ></button>
        </div>
        <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: left">
          <button class="icon-btn icon-arrow-left"     @click="prevObject" v-html="prevLabel" style="float: left; margin-left: -5px" ></button>
        </div>
        <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: left; margin-bottom: 3em;">
          <router-link :to="{ name: 'imprint' }" class="icon-btn icon-arrow"       style="float: left;">{{ $t('imprint') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Content from '@/components/Content'

export default {
  name: 'Exponat',
  components: { Content },
//  components: { Content, ExhibitNav },
  data () {
    return {
      container: null,
      exhibitNavToggled: false,
      stickExhibitNav: false
    }
  },
  props: {
    title: {
      type: [Boolean, String],
      default: false
    },
    content: {
      type: Object
    },
    back: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    last: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleExhibitNav () {
      this.exhibitNavToggled = !this.exhibitNavToggled
    },
    nextObject() {
      console.log("Exponat nextObject from " + this.index)
      this.$emit('next')
    },
    prevObject() {
      console.log("Exponat prevObject from " + this.index)
      if (this.index > 0)
        this.$emit('tomenu')
      else
        this.$emit('tointro')
    }
  },

  computed: {
    nextLabel () {
      let label = this.$t('next-object')
      if (this.back && this.index < 4) {
        label = this.$t('next-top')
      } else if (this.back && this.index >= 4) {
        label = this.$t('next-flop')
      }

      if (this.last) {
        label = this.$t('to-end')
      }

      return label
    },
    prevLabel () {
      let label = 'Zurück'
      if (this.index > 0)
        label = 'Zurück zum Menü'
      return label
    }
  },

  mounted () {
    const scrollWrapper = this.$vnode.elm.parentNode.parentNode
    if (scrollWrapper.classList.contains('scroll-enabled')) {
      this.container = scrollWrapper
    }
  }
}
</script>
