<template>
  <div class="exhibit-nav" :class="className">
    <ul>
      <li v-for="(link, i) in nav" :key="`exhibit-nav-${i}`">
        <button class="icon-btn icon-arrow" @click="scrollTo(link.slug)">{{ link.title }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ExhibitNav',
  props: {
    className: {
      type: String,
      default: ''
    },
    nav: {
      type: Array
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    container: {
      type: [HTMLDivElement, String]
    }
  },
  methods: {
    scrollTo (anchor) {
      const options = {}
      if (this.horizontal) {
        options.x = true
        options.y = false
      }

      if (this.container) {
        options.container = this.container
      }

      options.offset = (window.outerWidth >= 768 || document.querySelector('.page.exhibit') === null) ? -60 : -124

      this.$scrollTo(anchor, 700, options)
      this.$emit('click')
    }
  }
}
</script>
