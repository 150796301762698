<template>
  <div class="text" v-html="content.text" ref="text"></div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: ['content'],
  mounted () {
    /*
    const links = this.$refs.text.querySelectorAll('a')
    links.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault()
        const re = /\/[a-z]{2}\/(exhibits|tours)\/([\d]+)/
        const match = link.href.match(re)
        if (match.length === 3 && ['tours', 'exhibits'].indexOf(match[1]) > -1) {
          const name = match[1].substring(0, match[1].length - 1)
          const id = match[2]
          this.$router.push({ name, params: { id } }).catch(() => {console.log("bubu")})
        }
      })
    })
    */
  }
}
</script>
