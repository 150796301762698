<template>
  <div class="page tour" :class="`tour-${tour.typ}`">
    <div v-if="tour.titel.length > 0">
      <PositionOverlay
        :showVideo="showVideo"
        :animation="animation"
        @end="fadeOut"
        :switchSideLabel="switchSideLabel"
        :cubeSide="cubeSide"
        :cubePos="cubePos"
        :exhibitNumber="exhibitNumber"
      ></PositionOverlay>

      <div class="content" :class="{ 'with-bullets': showBullets }">
        <SnapSlider @change="changeSlide" ref="slider">
          <div class="slide intro-slide">
            <div class="inner-slide inner-movedown">
              <div class="page-header">
                <div class="container">
                  <h1 class="page-title" v-html="tour.titel"></h1>
                </div>
              </div>

              <div class="page-content container">
                <div class="raster-img" style="margin-top: 4em; margin-bottom: -2em;"><div class="raster-white"><div class="white"><img src="@/assets/KeyNoisy2k.jpg" class="raster-fill"></div></div></div>
                <nl2br className="tour-description" tag="p" v-if="tour.einleitung && tour.einleitung.length > 0" :text="tour.einleitung" />

                <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: right; margin-top: 1em;">
                  <button class="icon-btn icon-arrow"     @click="nextObject" style="padding: 0;" >{{ $t('next-object') }}</button>
                </div>
                <div class="swipe-info hide-desktop">
                  <img src="@/assets/icons/Hinweis_swipe.svg" style="margin-top: 1em">
                  <span>{{ $t('swipe-info') }}</span>
                </div>
                <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: left; margin-top: 1em;">
                  <router-link :to="{ name: 'home' }"    class="icon-btn icon-arrow-left" >Zurück</router-link>
                </div>
                <div class="exhibit-nav" style="display: inline-block; width: 100%; text-align: left; margin-top: 2em; margin-bottom: 2em;">
                  <router-link :to="{ name: 'imprint' }" class="icon-btn icon-arrow"      >{{ $t('imprint') }}</router-link>
                </div>

              </div>
            </div>
          </div>

          <div class="slide" v-for="(exhibit, i) in tour.sequenz" :key="`exhibit-${i}`">
            <div class="inner-slide inner-movedown">

              <component
                :is="exhibit.typ"
                :content="exhibit"
                @next="$refs.slider.next()"
                @prev="$refs.slider.prev()"
                @tomenu="$refs.slider.select(1)"
                @tointro="$refs.slider.select(0)"
                @back="backToStart"
                :back="tour.typ === 'tof'"
                :title="tour.typ === 'kids' ? tour.titel : false"
                :index="i"
                :last="i + 1 === tour.sequenz.length"
              ></component>
            </div>
          </div>

          <div
            class="slide thanks-slide page-fullscreen"
          >
            <div class="inner-slide">
              <div class="container">
                <div class="page-title" style="width: 100%;">
                  {{ $t(tour.typ === 'quiz' ? 'congratulations' : 'thank-you') }}
<!--                  <div class="raster-img" style="margin-top: 2em;"><div class="raster-red"><img src="@/assets/KeyNoisy2k.jpg" class="raster-fill"></div></div> -->
                  <div class="raster-img" style="margin-top: 1em; margin-bottom: 1em;"><div class="raster-white"><div class="logo-bsb"><img src="@/assets/KeyNoisy2k.jpg" class="raster-fill"></div></div></div>
                </div>
                <div class="content">
                  <nl2br v-if="tour.typ !== 'quiz' && tour.abschluss.length > 0" tag="p" :text="tour.abschluss" />
                </div>
                <div style="width: 100%; text-align: right;">
                  <router-link class="icon-btn icon-arrow" :to="{ name: 'home' }">Zurück zum Start</router-link><br>
                </div>
                <div style="width: 100%; text-align: left; margin-top: 2em; margin-bottom: 2em;">
                  <router-link class="icon-btn icon-arrow" :to="{ name: 'imprint' }">Impressum</router-link>
                </div>
              </div>
            </div>
          </div>

        </SnapSlider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header.vue'
import Exponat from '@/components/Exponat.vue'
import QuizSlide from '@/components/QuizSlide.vue'
import Cube from '@/components/Cube.vue'
import SnapSlider from '@/components/SnapSlider.vue'
import Block from '@/components/Block'
import PositionOverlay from '../components/PositionOverlay'
import ExhibitNav from "@/components/ExhibitNav.vue";
import snapSlider from "@/components/SnapSlider.vue";

export default {
  name: 'Tour',
  components: {
    ExhibitNav,
    Header,
    QuizSlide,
    SnapSlider,
    tourexponat: Exponat,
    kubus: Cube,
    Block,
    PositionOverlay
  },

  data () {
    return {
      dragging: false,
      firstExhibitIndex: 1,
      currentSlide: 0,
      bullets: [],
      cubeSide: '',
      cubePos: { x: false, y: false },
      showVideo: false,
      animation: '',
      showingLast: false,
      switchSideLabel: '',
      exhibitNumber: false
    }
  },

  async mounted () {
    await this.$store.dispatch('fetchLanguages');

        // first fetch list of tours if necessary
    if (this.tours.length === 0) {
      await this.$store.dispatch('fetchTours')
    }

    // fetch single tour
    console.log("Tour mounted " + this.$store.getters.activeLang);
    await this.$store.dispatch('fetchTour', this.$route.params.id)

    if (this.tour.typ === 'tof') {
      this.firstExhibitIndex = 2
    } else {
      this.firstExhibitIndex = 1
    }

    for (let i = 0; i <= this.$refs.slider.slides - 1; i += 1) {
      this.bullets.push({ current: i <= this.$refs.slider.currentSlide })
    }

    if (this.$route.params.item > -1)
      var itemindex = +this.$route.params.item
    itemindex += 1
      console.log("tour mounted item "  + itemindex)
      this.$refs.slider.select(itemindex)
  },

  computed: {
    snapSlider() {
      return snapSlider
    },
    ...mapState(['tours', 'tour', 'quiz']),
    showBullets () {
      return this.tour.typ === 'kids' || (this.tour.typ !== 'tof' && !this.showingLast)
    }
  },

  // reset tour
  beforeDestroy () {
    this.$store.commit('setTour', { titel: '', sequenz: [] })
  },

  beforeRouteLeave (to, from, next) {
    this.cubeSide = ''
    next()
  },

  methods: {
    nextObject() {
      console.log("Tour nextObject")
      this.$refs.slider.next()
    },
    changeSlide (slide) {
      this.bullets.forEach((bullet, i) => {
        bullet.current = i <= slide
      })
      this.currentSlide = slide

      // check if showing the last slide
      this.showingLast = slide === this.$refs.slider.slides - 1

      // update cube side and position
      if (slide > 0 && slide <= this.tour.sequenz.length) {
        const tourSlide = this.tour.sequenz[slide - this.firstExhibitIndex]
        if (typeof tourSlide !== 'undefined') {
          this.cubeSide = tourSlide.kubus_seite || ''
          this.cubePos = {
            x: tourSlide.kubus_pos_x || false,
            y: tourSlide.kubus_pos_y || false
          }
          this.exhibitNumber = tourSlide.exponat_id || false
        }
      }

      // stop all playing videos
      for (const video of document.querySelectorAll('video')) {
        video.pause()
      }
      for (const audio of document.querySelectorAll('audio')) {
        audio.pause()
      }
    },

    fadeOut () {
      this.showVideo = false
    },

    backToStart () {
      if (this.tour.typ === 'tof') {
        this.$refs.slider.select(1)
      }
    }
  }
}
</script>
