<template>
  <figure class="image">
    <div class="img">
      <!--
      <button class="maximize" @click="enterFullscreen"></button>
      <img :src="`${content.url_klein}`">
      <div class="raster-img"><div class="raster-white"><div class="logo-bsb"><img :src="`${content.url_klein}`" class="raster-fill"></div></div></div>
      -->
      <div class="raster-img" :style="bgstyle"><div class="raster-white"><div class="white"><img :src="`${content.url_klein}`" class="raster-fill"></div></div></div>
    </div>
    <!--
    <figcaption>
      {{ content.bildunterschrift }}
      <span v-if="content.copyright">© {{ content.copyright }}</span>
    </figcaption>
    -->
  </figure>
</template>
<script>
export default {
  name: 'ImageBlock',
  props: ['content'],
  methods: {
    enterFullscreen () {
      this.$store.commit('enterFullscreen', this.content.url_gross)
    }
  },
  computed: {
    bgstyle() {
      return `background: url("${this.content.url_klein}") no-repeat;`
    }
  }
}
</script>
