<template>
  <div class="audio">
<!--    <h4>«{{ content.audiounterschrift }}»</h4>
    <p>© {{ content.copyright }}</p>
-->
    <audio ref="audio" @timeupdate="timeUpdate" @ended="playing = false" :src="content.url_mp3"></audio>

    <PlayerControls
      @play="play"
      @pause="pause"
      @seek="seek"
      :time="time"
      :duration="duration"
      v-model="playing"
      @volume="volume"
    >
    </PlayerControls>
  </div>
</template>

<script>
import PlayerControls from '@/components/PlayerControls'

export default {
  name: 'Audio',
  props: ['content'],
  components: { PlayerControls },
  data () {
    return {
      time: 0,
      duration: 0,
      playing: false
    }
  },
  methods: {
    timeUpdate () {
      this.time = this.$refs.audio.currentTime / this.duration * 100
    },
    seek (pos) {
      this.$refs.audio.currentTime = pos / 100 * this.duration
    },
    play () {
      this.$refs.audio.play()
    },
    pause () {
      this.$refs.audio.pause()
    },
    volume (val) {
      this.$refs.audio.volume = val
    }
  },
  mounted () {
    let checkAudioState

    if (typeof this.$refs.audio !== 'undefined') {
      checkAudioState = setInterval(() => {
        if (typeof this.$refs.audio !== 'undefined' && this.$refs.audio.readyState === 4) {
          this.duration = this.$refs.audio.duration
          clearInterval(checkAudioState)
        }
      }, 10)
    } else {
      clearInterval(checkAudioState)
    }
  }
}
</script>
