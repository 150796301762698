<template>
  <div class="3d-carousel" ref="pano">
    <div class="frames" v-if="content.urls.length > 0">
      <button
        class="rotate-left"
        @mousedown="rotateLeft"
        @touchstart="rotateLeft"
        @mouseup="clearRotation"
        @touchend="clearRotation"
        @mouseleave="clearRotation"
      ></button>
      <button
        class="rotate-right"
        @mousedown="rotateRight"
        @touchstart="rotateRight"
        @mouseup="clearRotation"
        @touchend="clearRotation"
        @mouseleave="clearRotation"
      ></button>
      <img class="frame" :src="frame" v-for="(frame, i) in content.urls" :key="`frame+${i}`" v-show="active === i"/>
    </div>

    <p v-if="content.panounterschrift || content.copyright">
      <span v-if="content.panounterschrift">{{ content.panounterschrift }}</span>
      <span v-if="content.copyright">© {{ content.copyright }}</span>
    </p>
  </div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
  name: 'PanoCarousel',
  props: ['content'],

  data () {
    return {
      active: 0,
      rotating: null
    }
  },

  mounted () {
    const manager = new Hammer.Manager(this.$refs.pano)
    const pan = new Hammer.Pan()
    manager.add(pan)

    let distance = 0

    manager.on('panmove', e => {
      this.clearRotation()

      const newDistance = Math.floor(e.distance / 10)
      if (newDistance > distance) {
        distance = newDistance
        if (e.offsetDirection === 2) {
          this.active = this.active === 0 ? this.content.anzahl - 1 : this.active - 1
        } else if (e.offsetDirection === 4) {
          this.active = this.active < this.content.anzahl - 1 ? this.active + 1 : 0
        }
      }
    })

    manager.on('panend', () => (distance = 0))

    this.rotating = setInterval(() => {
      this.active = this.active < this.content.anzahl - 1 ? this.active + 1 : 0
    }, 200)
  },

  methods: {
    rotateLeft () {
      this.clearRotation()
      this.rotating = setInterval(() => {
        this.active = this.active === 0 ? this.content.anzahl - 1 : this.active - 1
      }, 100)
    },

    rotateRight () {
      this.clearRotation()
      this.rotating = true
      this.rotating = setInterval(() => {
        this.active = this.active < this.content.anzahl - 1 ? this.active + 1 : 0
      }, 100)
    },

    clearRotation () {
      window.clearInterval(this.rotating)
    }
  }
}
</script>

<style lang="scss">
.frames {
  position: relative;
 -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

}

.rotate-left,
.rotate-right {
  position: absolute;
  display: block;
  background: url(../assets/icons/3D-Pfeil_rechts.svg) no-repeat;
  background-size: 100% 100%;
  top: 50%;
  margin-top: -24px;
  z-index: 2;
  width: 28px;
  height: 24px;
}

.rotate-right {
  right: 12px;
}

.rotate-left {
  left: 12px;
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .rotate-left,
  .rotate-right {
    width: 48px;
    height: 44px;
  }

  .rotate-right {
    right: 22px;
  }

  .rotate-left {
    left: 22px;
    transform: rotate(180deg);
  }
}

.frame {
  pointer-events: none;
  width: 100%;
  height: auto;
}
</style>
