<template>
  <div class="fullscreen">
    <button class="minimize svg-btn" @click="exitFullscreen">
      <svg xmlns="http://www.w3.org/2000/svg" width="39.414" height="39.426" viewBox="0 0 39.414 39.426">
        <g id="Gruppe_135" data-name="Gruppe 135" transform="translate(-679.793 -48.793)">
        <path id="Pfad_49" data-name="Pfad 49" d="M718,68.5l-7.1-7.1,5.6-5.5-4.3-4.3-5.6,5.5L699.5,50V68.5Z" fill="#1a1a18" stroke="#f6f6f6" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Pfad_50" data-name="Pfad 50" d="M681,68.5l7.1,7.1-5.6,5.5,4.3,4.3,5.6-5.6,7.1,7.2V68.5Z" fill="#1a1a18" stroke="#f6f6f6" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </button>

    <button class="zoom-in svg-btn" :class="{ 'disabled': zoom >= 2.5 }" @click="zoomIn">
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
        <g id="Gruppe_133" data-name="Gruppe 133" transform="translate(-684.9 -127.1)">
        <path id="Pfad_47" data-name="Pfad 47" d="M696.2,138.5V127.6h6.4v10.9h10.8v6.1H702.6v11h-6.4v-11H685.4v-6.1Z" stroke="#f6f6f6" stroke-width="1"/>
        </g>
      </svg>
    </button>

    <button class="zoom-out svg-btn" :class="{ 'disabled': zoom = 0 }" @click="zoomOut">
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="7" viewBox="0 0 29 7">
        <g id="Gruppe_134" data-name="Gruppe 134" transform="translate(-684.9 -195.5)">
        <path id="Pfad_48" data-name="Pfad 48" d="M685.4,202v-6h28v6Z" stroke="#f6f6f6" stroke-width="1"/>
        </g>
      </svg>
    </button>

    <div class="img-wrapper">
      <img :src="img" ref="img">
    </div>
  </div>
</template>

<script>
import panzoom from 'panzoom'

export default {
  name: 'Fullscreen',
  props: ['img'],
  data () {
    return {
      zoom: 1,
      panzoom: null
    }
  },
  mounted () {
    this.panzoom = panzoom(this.$refs.img, {
      maxZoom: 2.5,
      minZoom: 1,
      zoomDoubleClickSpeed: 1
    })

    this.panzoom.on('zoomend', this.zoomEnd)
  },
  methods: {
    exitFullscreen () {
      this.$store.commit('exitFullscreen')
    },
    zoomEnd () {
      const transform = this.$refs.img.style.transform
      if (transform) {
        const re = /matrix\((\d.\d+),/
        const match = transform.match(re)
        if (match && match.length === 2) {
          this.zoom = parseFloat(match[1])
        }
      }
    },
    zoomIn () {
      if (this.panzoom) {
        this.panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 1.5)
      }
    },
    zoomOut () {
      if (this.panzoom) {
        this.panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 0.75)
      }
    }
  }
}
</script>
