<template>
  <h1 class="title">{{ content.text }}</h1>
</template>

<script>
export default {
  name: 'Title',
  props: ['content']
}
</script>
